var LazyEmbed = (function() {
	var lazyembeds 			= $('.lazy-embed');
	var lazyEmbedObserver   = new IntersectionObserver(function(entries) {
		$(entries).each(function(index, entry) {
			if(entry.isIntersecting) {
				var $target = $(entry.target);
				var iframe  = $target.find('template').html();

				$target.html(iframe);

				lazyEmbedObserver.unobserve(entry.target);
			}
		});
	});

	lazyembeds.each(function() {
		lazyEmbedObserver.observe(this);
	});
})();