$('[data-toggle="addFile"]').each(function() {
	var btn 		= $(this);
	var target 		= btn.data('target');
	var prefix 		= btn.data('prefix');
	var container 	= $(target);
	var template 	= container.find('.template')[0].outerHTML;
	var count		= 1;

	btn.click(function() {
		// var newFile = template.clone();
		

		var row = document.createElement('div');

		$(row).addClass('form-row form-group row-file-'+prefix+'-'+count);
		$(row).append('<div class="col"></div><div class="col-auto d-flex"></div>');
		$(row).find('.col').append(template);
		
		$(row).find('.filecustom-input').attr('id', prefix+'-'+count);
		$(row).find('label').attr('for', prefix+'-'+count);

		$(row).find('.form-group').removeClass('form-group');


		var btnRemove = document.createElement('button');

		$(btnRemove)
			.attr('type', 'button')
			.addClass('btn btn-danger btn-float btn-remover')
			.attr('aria-label', 'Remover arquivo')
			.append('<i class="fas fa-times"></i>');

		$(btnRemove).click(function() {
			$(row).remove();

			count--
		});
		$(row).find('.col-auto').append(btnRemove);

		container.append(row);

		$(row).find('.filecustom').fileCustom();

		count++
	});
});